import React from "react";
import { Link } from "react-router-dom";

const NewsletterList = ({ newsletterData, currentLanguage }) => { 
    return (
        <section className="bg-gray-100 py-10">
            <div className="container mx-auto px-5">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                    {newsletterData.map((newsletter) => (
                        <div key={newsletter.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
                            {newsletter.typeOfNewsletter.name === 'Old' ? (
                                // Link to PDF for "Old" newsletter
                                <a href={newsletter.oldNewsletterPdf.mediaFile.url} target="_blank" rel="noopener noreferrer">
                                    <img 
                                        src={newsletter.newsletterCoverImage.mediaImage6.url} 
                                        alt={newsletter.newsletterCoverImage.mediaImage6.alt} 
                                        className="w-full h-56 object-cover"
                                    />
                                </a>
                            ) : (
                                // Link to newsletter details page for other types
                                <Link to={`/${currentLanguage === 'hi' ? 'hi/' : ''}newsletter-3/${newsletter.path.split("/").pop()}`}>
                                    <img 
                                        src={newsletter.newsletterCoverImage.mediaImage6.url} 
                                        alt={newsletter.newsletterCoverImage.mediaImage6.alt} 
                                        className="w-full h-56 object-cover"
                                    />
                                </Link>
                            )}

                            <div className="p-5">
                                {newsletter.typeOfNewsletter.name === 'Old' ? (
                                    <a href={newsletter.oldNewsletterPdf.mediaFile.url} target="_blank" rel="noopener noreferrer">
                                        <h5 className="text-xl font-bold text-gray-800">{newsletter.title}</h5>
                                    </a>
                                ) : (
                                    <Link to={`/${currentLanguage === 'hi' ? 'hi/' : ''}newsletter-3/${newsletter.path.split("/").pop()}`}>
                                        <h5 className="text-xl font-bold text-gray-800">{newsletter.title}</h5>
                                    </Link>
                                )}
                                <p className="text-gray-600 mt-2">{newsletter.shortDescription}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default NewsletterList;
