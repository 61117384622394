import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import NodeContentQueryEn from '../QueriesEn/NodeContentQueryEn';
import NodeContentQueryHi from '../QueriesHi/NodeContentQueryHi';

const PageDataFetcher = ({ setPageNodeData, currentLanguage }) => {
  const nodeContentQuery = currentLanguage === 'en' ? NodeContentQueryEn : NodeContentQueryHi;

  const { loading, error, data } = useQuery(nodeContentQuery, {
    fetchPolicy: 'network-only',
    variables: { language: currentLanguage },
  });

  useEffect(() => {
    if (error) {
      console.error('Error fetching page items:', error);
    }
    if (data) {
      const nodes = data.nodePages.nodes;
      setPageNodeData(nodes);
      // console.log('Fetched nodes data:', nodes);
    }
  }, [data, error, setPageNodeData]);

  if (loading) return <p>Loading...</p>;
  return null;
};

export default PageDataFetcher;
