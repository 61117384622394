import { gql } from '@apollo/client';

const MainNavigationMenuHi = gql`
query MyQuery {
  menu(name: MAIN, langcode: "hi") {
    id
    items {
      title
      url
      children {
        title
        url
      }
    }
  }
}
`;
 export default MainNavigationMenuHi;