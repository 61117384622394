import React from "react";
import Slider from "react-slick";
import "../../index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";
import BackgroundImage from "../happening-bg.jpg";

const Eventshome = ({ eventsData, currentLanguage }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="events-section mt-10 relative bg-navy-blue bg-opacity-80 pb-16"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundBlendMode: "overlay",
      }}
    >
      <div className="mx-auto px-4 pt-10 max-w-screen-xl">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase text-white text-center relative">
          Events
          <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
            <img src={SvgShikshaFlower} alt="headingflower" className="h-8 sm:h-10 lg:h-12  p-1 sm:p-2" />
          </div>
        </h2>

        {eventsData && eventsData.length > 0 ? (
          <Slider {...settings} className="relative events-carousel">
          {eventsData.map((event) => (
            <div
              key={event.id}
              className="event-card max-w-xs p-4 bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden transition-transform duration-300 transform hover:scale-105"
            >
              <Link to={event.path}>
                <div className="w-full h-40 overflow-hidden flex justify-center items-center mb-3">
                  <img
                    src={event.imageAbout.url}
                    alt={event.imageAbout.alt || event.title}
                    className="object-cover w-full h-full rounded-md"
                  />
                </div>
                <h3 className="text-lg font-semibold text-gray-800 truncate">
                  {event.title}
                </h3>
                <p className="text-sm text-gray-700 overflow-hidden h-10 line-clamp-2" dangerouslySetInnerHTML={{ __html: event.body?.value || '' }}>
                  
                </p>
                <div className="text-center mt-3">
                  <Link to={event.path} className="text-blue-600 font-semibold hover:underline">
                    Read More
                  </Link>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
        
        ) : (
          <p className="text-center text-lg text-white">No events available</p>
        )}
      </div>
    </div>
  );
};

export default Eventshome;
