import { gql } from '@apollo/client';

const ProgramandCampaignQueryEn = gql`
  query ProgramandCampaignQueryEn {
    nodeProgramAndCampaigns(first: 10, langcode: "en") {
      nodes {
        id
        __typename
        title
        body {
          value
        }
        bannerImage {
          url
          alt
        }
        path
        uploadImage {
          ... on MediaProgramAndCampaignsImage {
            id
            name
            mediaImage1 {
              url
              alt
            }
          }
        }
        secondTitle
        video {
          ... on MediaVideo {
            id
            name
            mediaVideoFile {
              url
              name
            }
          }
        }
        thirdTitle
        thirdSectionDescription
      }
    }
  }
`;

export default ProgramandCampaignQueryEn;
