import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import VisionandMissionQueryEn from '../QueriesEn/VisionandMissionQueryEn';
import VisionandMissionQueryHi from '../QueriesHi/VisionandMissionQueryHi';

const VisionandMissionDataFetcher = ({ setVisionandMissionData, currentLanguage }) => {
  const visionandMissionQuery = currentLanguage === 'en' ? VisionandMissionQueryEn : VisionandMissionQueryHi;

  const { error, data } = useQuery(visionandMissionQuery, {
    fetchPolicy: 'network-only',
    variables: { language: currentLanguage },
  });

  useEffect(() => {
    if (error) {
      console.error('Error fetching Vision and Mission data:', error);
    }
    if (data) {
      const nodes = data.nodeVissionAndMissions.nodes;
      setVisionandMissionData(nodes); // Ensure setVisionandMissionData is correctly passed and a function
      // console.log('Fetched Vision and Mission data:', nodes);
    }
  }, [data, error, setVisionandMissionData]);

  return null;
};

export default VisionandMissionDataFetcher;
