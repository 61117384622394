import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import ReachandImpactQueryEn from '../QueriesEn/ReachandImpactQueryEn';
import ReachandImpactQueryHi from '../QueriesHi/ReachandImpactQueryHi';

const ReachandImpactDataFetcher = ({ setReachAndImpactData, currentLanguage }) => {
  const reachandImpactQuery = currentLanguage === 'en' ? ReachandImpactQueryEn : ReachandImpactQueryHi;

  const { error, data } = useQuery(reachandImpactQuery, {
    fetchPolicy: 'network-only',
    variables: { language: currentLanguage },
  });

  useEffect(() => {
    if (error) {
      console.error('Error fetching Reach and Impact data:', error);
    }
    if (data) {
      const nodes = data.nodeReachAndImpacts.nodes;
      setReachAndImpactData(nodes);
      // console.log('Fetched Reach and Impact data:', nodes);
    }
  }, [data, error, setReachAndImpactData]);

  return null;
};

export default ReachandImpactDataFetcher;
