// Breadcrumb.js
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();
  const pathParts = location.pathname.split("/").filter((part) => part);
  const [activeLink, setActiveLink] = useState(null);

  // Handle breadcrumb click
  const handleBreadcrumbClick = (index) => {
    setActiveLink(index);
  };

  return (
    <nav className="flex-wrap space-x-1 sm:space-x-2 px-4 text-sm sm:text-base">
      <ul className="flex">
        <li>
          <Link
            to="/"
            onClick={() => handleBreadcrumbClick(-1)}
            className={`${
              activeLink === -1 ? "text-yellow-400" : "text-white"
            } font-bold sm:font-medium sm:hover:underline sm:hover:text-yellow-400`}
          >
            Home
          </Link>
          <span className="text-white font-medium"> &gt; </span>
        </li>
        {pathParts.map((part, index) => {
          const path = `/${pathParts.slice(0, index + 1).join("/")}`;
          return (
            <li key={index} className="inline-flex items-center">
              <Link
                to={path}
                onClick={() => handleBreadcrumbClick(index)}
                className={`${
                  activeLink === index ? "text-yellow-400" : "text-white"
                } font-bold sm:font-medium sm:hover:underline sm:hover:text-yellow-400`}
              >
                {part.charAt(0).toUpperCase() +
                  part.slice(1).replace(/-/g, " ")}{" "}
                {/* Add space around the hyphen */}
              </Link>

              {index < pathParts.length - 1 && (
                <span className="text-white font-medium"> &gt; </span>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
