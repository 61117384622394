import React from "react";

const AboutImage = ({ imageUrl }) => {
  if (!imageUrl) {
    return null;
  }
  const fullImageUrl = `${imageUrl}`;
  return (
    <div className="mt-4">
      <img
        src={fullImageUrl}
        alt="About"
        className="lg:w-full xs:w-[98%]  h-auto object-cover rounded-lg"
      />
    </div>
  );
};

export default AboutImage;
