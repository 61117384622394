import { gql } from '@apollo/client';

const UmangQueryEn = gql`
query MyQuery {
  nodeResumeUploads(first: 10) {
    nodes {
      id
      path
      title
      __typename
      umangMannual {
        ... on ParagraphUmangMannual {
          id
          grade
          uploadMannual {
            name
            size
            url
          }
          year
        }
      }
      
    }
  }
}
`;

export default UmangQueryEn;