import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import AppContent from './components/AppContent';
import { BaseUrlProvider } from './contexts/BaseUrlContext';
import { ApolloProvider } from '@apollo/client';
import client from './contexts/ApolloClient';
import Webformdisplay from './Webformdisplay';
import SubscribeForm from './components/Form/SubscribeForm'

const App = () => {
  // useEffect(() => {
  //   const disableContextMenu = (e) => e.preventDefault();
  //   const disableKeyDown = (e) => {
  //     if (e.key === 'F12' || (e.ctrlKey && (e.key === 'u' || e.key === 'c' || e.key === 'v'))) {
  //       e.preventDefault();
  //     }
  //   };
  //   const disableSelection = () => {
  //     document.body.style.userSelect = 'none';
  //     document.body.style.webkitUserSelect = 'none';
  //     document.body.style.mozUserSelect = 'none';
  //     document.body.style.msUserSelect = 'none';
  //   };

  //   document.addEventListener('contextmenu', disableContextMenu);
  //   document.addEventListener('keydown', disableKeyDown);
  //   disableSelection();

  //   return () => {
  //     document.removeEventListener('contextmenu', disableContextMenu);
  //     document.removeEventListener('keydown', disableKeyDown);
  //     document.body.style.userSelect = '';
  //     document.body.style.webkitUserSelect = '';
  //     document.body.style.mozUserSelect = '';
  //     document.body.style.msUserSelect = '';
  //   };
  // }, []);

  return (
    <ApolloProvider client={client}>
      <Router>
        <ErrorBoundary>
        <BaseUrlProvider> {/* Wrap your components with BaseUrlProvider */}
            <AppContent />
            {/* <Webformdisplay /> */}
            {/* <ResumeUploads /> */}
            {/* <SubscribeForm /> */}
          </BaseUrlProvider>
        </ErrorBoundary>
      </Router>
    </ApolloProvider>
  );
};

export default App;
