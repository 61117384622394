import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./ContentView/Navbar";
import MenuFetcher from "./DataFetcher/MenuFetcher";
import PageDataFetcher from "./DataFetcher/PageDataFetcher";
import WorkingModelDataFetcher from "./DataFetcher/WorkingModelDataFetcher";
import ReachandImpactDataFetcher from "./DataFetcher/ReachandImpactDataFetcher";
import LeadershipDataFetcher from "./DataFetcher/LeadershipDataFetcher";
import NewsletterDataFetcher from "./DataFetcher/NewsletterDataFetcher";
import VisionandMissionDataFetcher from "./DataFetcher/VisionandMissionDataFetcher";
import ProgramandCampaignDataFetcher from "./DataFetcher/ProgramandCampaignDataFetcher";
import VideosDataFetcher from "./DataFetcher/VideosDataFetcher";
import EventsDataFetcher from "./DataFetcher/EventsDataFetcher";
import StoriesDataFetcher from "./DataFetcher/StoriesDataFetcher";
import FormDataFetcher from "./DataFetcher/FormDataFetcher";
import AnnualReportDataFetcher from "./DataFetcher/AnnualReportDataFetcher";
import RoutesMapper from "./RoutesMapper";
import UmangDataFetcher from "./DataFetcher/UmangDataFetcher";
import HomePageDataFetcher from "./DataFetcher/HomePageDataFetcher";
import FooterMenuFetcher from "./DataFetcher/FooterMenuFetcher";
import { useBaseUrl } from "../contexts/BaseUrlContext"; // Import the useBaseUrl hook
import FooterPage from "./ContentView/FooterPage";

const AppContent = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [pageNodeData, setPageNodeData] = useState([]);
  const [workingModelData, setWorkingModelData] = useState([]);
  const [visionAndMissionData, setVisionAndMissionData] = useState([]);
  const [reachAndImpactData, setReachAndImpactData] = useState([]);
  const [leadershipData, setLeadershipData] = useState([]);
  const [newsletterData, setNewsletterData] = useState([]);
  const [programAndCampaignData, setProgramAndCampaignData] = useState([]);
  const [videosData, setVideosData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [storiesData, setStoriesData] = useState([]);
  const [umangData, setUmangData] = useState([]);
  const [homePageData, setHomePageData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [matchedNodes, setMatchedNodes] = useState([]);
  const [annualReportData, setAnnualReportData] = useState([]);
  const [footerMenuItems, setFooterMenuItems] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const location = useLocation();
  const navigate = useNavigate();
  const baseUrl = useBaseUrl(); // Access the base URL from the context

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [location]);

  const switchLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "hi" : "en";
    setCurrentLanguage(newLanguage);
    const newPath =
      newLanguage === "en"
        ? location.pathname.replace("/hi", "")
        : `/hi${location.pathname}`;
    navigate(newPath);
  };

  useEffect(() => {
    if (location.pathname.startsWith("/hi")) {
      setCurrentLanguage("hi");
    } else {
      setCurrentLanguage("en");
    }
  }, [location]);

  useEffect(() => {
    if (menuItems.length > 0) {
      const updatedMatchedNodes = menuItems.map((menuItem) => {
        // console.log('Menu Item:', menuItem); // Check menu item details

        // Extract child URLs or use the menu item URL if children are not available
        const childUrls =
          menuItem.childrenUrls && menuItem.childrenUrls.length > 0
            ? menuItem.childrenUrls
            : [menuItem.url];

        const matchingNodes = childUrls.map((childUrl) => {
          // Construct URL based on current language
          const url =
            currentLanguage === "en"
              ? childUrl.replace(/^\/hi/, "")
              : `/hi${childUrl.replace(/^\/hi/, "")}`;
          // console.log('Matching URL:', url); // Verify the constructed URL

          // Attempt to find a matching node
          let matchedNode =
            pageNodeData.find((node) => node.path === url) ||
            workingModelData.find((node) => node.path === url) ||
            visionAndMissionData.find((node) => node.path === url) ||
            reachAndImpactData.find((node) => node.path === url) ||
            programAndCampaignData.find((node) => node.path === url) ||
            videosData.find((node) => node.path === url) ||
            annualReportData.find((node) => node.path === url) ||            
            formData.find((node) => node.path === url);

          // console.log('Matched Node:', matchedNode); // Log the matched node or null
          return matchedNode;
        });

        // Check if no child nodes matched and fallback to matching menu item URL
        const fallbackNode = matchingNodes.find((node) => node !== undefined);
        if (!fallbackNode) {
          const fallbackUrl =
            currentLanguage === "en"
              ? menuItem.url
              : `/hi${menuItem.url.replace(/^\/hi/, "")}`;

          const matchedFallbackNode =
            pageNodeData.find((node) => node.path === fallbackUrl) ||
            workingModelData.find((node) => node.path === fallbackUrl) ||
            visionAndMissionData.find((node) => node.path === fallbackUrl) ||
            reachAndImpactData.find((node) => node.path === fallbackUrl) ||
            programAndCampaignData.find((node) => node.path === fallbackUrl) ||
            videosData.find((node) => node.path === fallbackUrl) ||
            annualReportData.find((node) => node.path === fallbackUrl) ||
            formData.find((node) => node.path === fallbackUrl);

          // console.log('Fallback Node:', matchedFallbackNode); // Log the fallback node or null
          return {
            menuItem,
            matchedNodes: [...matchingNodes, matchedFallbackNode].filter(
              (node) => node !== undefined
            ),
          };
        }

        return {
          menuItem,
          matchedNodes: matchingNodes,
        };
      });

      setMatchedNodes(updatedMatchedNodes);
    }
  }, [
    menuItems,
    pageNodeData,
    workingModelData,
    visionAndMissionData,
    reachAndImpactData,
    programAndCampaignData,
    annualReportData,
    videosData,
    formData,
    currentLanguage,
  ]);

  return (
    <div>
      
      <Navbar 
        menuItems={menuItems}
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        currentLanguage={currentLanguage}
        switchLanguage={switchLanguage}
      />
      <MenuFetcher
        setMenuItems={setMenuItems}
        currentLanguage={currentLanguage}
      />
      
      <PageDataFetcher
        setPageNodeData={setPageNodeData}
        currentLanguage={currentLanguage}
      />
      <WorkingModelDataFetcher
        setWorkingModelData={setWorkingModelData}
        currentLanguage={currentLanguage}
      />
      <VisionandMissionDataFetcher
        setVisionandMissionData={setVisionAndMissionData}
        currentLanguage={currentLanguage}
      />
      <ReachandImpactDataFetcher
        setReachAndImpactData={setReachAndImpactData}
        currentLanguage={currentLanguage}
      />
      <ProgramandCampaignDataFetcher
        setProgramAndCampaignData={setProgramAndCampaignData}
        currentLanguage={currentLanguage}
      />
      <VideosDataFetcher setVideosData={setVideosData} currentLanguage={currentLanguage}/>
      <AnnualReportDataFetcher setAnnualReportData={setAnnualReportData} currentLanguage={currentLanguage}/>
      <LeadershipDataFetcher
        setLeadershipData={setLeadershipData}
        currentLanguage={currentLanguage}
      />
      <NewsletterDataFetcher setNewsletterData={setNewsletterData} currentLanguage={currentLanguage} />
      <EventsDataFetcher
        setEventsData={setEventsData}
        currentLanguage={currentLanguage}
      />
      <StoriesDataFetcher
        setStoriesData={setStoriesData}
        currentLanguage={currentLanguage}
      />
      <UmangDataFetcher
        setUmangData={setUmangData}
        currentLanguage={currentLanguage}
      />
      <HomePageDataFetcher setHomePageData={setHomePageData} currentLanguage={currentLanguage} />
      <FooterMenuFetcher setFooterMenuItems={setFooterMenuItems} currentLanguage={currentLanguage} />
      <FormDataFetcher
        setFormData={setFormData}
        baseUrl={baseUrl}
        currentLanguage={currentLanguage}
      />
      {/* Update prop name */}
      <RoutesMapper
        matchedNodes={matchedNodes}
        currentLanguage={currentLanguage}
        isMobileMenuOpen={isMobileMenuOpen}
        leadershipData={leadershipData}
        newsletterData={newsletterData}
        eventsData={eventsData}
        storiesData={storiesData}
        pageNodeData={pageNodeData}
        umangData={umangData}
        homePageData={homePageData}
        programAndCampaignData={programAndCampaignData}
        // footerMenuItems = {footerMenuItems}
      />
      <FooterPage footerMenuItems={footerMenuItems} />
    </div>
  );
};

export default AppContent;
