import React, { useState, useEffect } from 'react';

const ReCaptcha = ({ onVerify, onError, handleCareerFormSubmit }) => {
  const [puzzleMode, setPuzzleMode] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');
  const [puzzle, setPuzzle] = useState({ num1: 0, num2: 0 });
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null); // null for unknown state, false for incorrect, true for correct

  // Generate a random puzzle question
  const generatePuzzle = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    setPuzzle({ num1, num2 });
  };

  // Attempt to execute reCAPTCHA, fallback to puzzle if it fails
  const generateToken = async () => {
    if (window.grecaptcha && window.grecaptcha.enterprise) {
      window.grecaptcha.enterprise.ready(async () => {
        setCaptchaLoaded(true);
        try {
          const token = await window.grecaptcha.enterprise.execute(
            '6Lf2Mn0qAAAAADCffDBITGlU-XGdl1PNxyFpY1S-',
            { action: 'submit' }
          );
          onVerify(token);
        } catch (error) {
          console.error("reCAPTCHA error:", error);
          setPuzzleMode(true); // Switch to puzzle mode if reCAPTCHA fails
          generatePuzzle();
        }
      });
    } else {
      setPuzzleMode(true); // Activate puzzle mode if reCAPTCHA is not available
      generatePuzzle();
    }
  };

  // Timeout to switch to puzzle mode if reCAPTCHA does not load
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!captchaLoaded) {
        setPuzzleMode(true);
        generatePuzzle();
      }
    }, 5000); // 5 seconds timeout

    generateToken();

    return () => clearTimeout(timer);
  }, []);

  // Validate the user's answer to the puzzle
  const validatePuzzle = () => {
    const correctAnswer = puzzle.num1 + puzzle.num2;
    const isValid = parseInt(userAnswer, 10) === correctAnswer;
    setIsAnswerCorrect(isValid);
    return isValid;
  };

  const handleAnswerChange = (e) => {
    setUserAnswer(e.target.value);
    if (e.target.value === '') {
      setIsAnswerCorrect(null); // Reset validation state if the input is empty
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the puzzle answer
    if (!userAnswer || !validatePuzzle()) {
      setIsAnswerCorrect(false); // Set incorrect if validation fails
      onError('Please solve the puzzle correctly.');
      return;
    }

    // If the answer is correct, proceed with generating the reCAPTCHA token
    setIsAnswerCorrect(true); // Correct answer

    // Trigger the career form submit after verifying the puzzle answer
    handleSubmit();

    // Now that the answer is correct, generate the reCAPTCHA token
    generateToken();

    // Reset the puzzle for next use
    setUserAnswer('');
    setPuzzleMode(false);
  };

  return (
    <div>
      {!captchaLoaded && <p>Loading reCAPTCHA...</p>}
      {puzzleMode && (
        <div className="puzzle-form">
          <p>
            Solve this to verify: {puzzle.num1} + {puzzle.num2} = ?
          </p>
          <input
            type="number"
            value={userAnswer}
            onChange={handleAnswerChange}
            placeholder="Your answer"
            className="input"
            style={{
              padding: '8px',
              fontSize: '16px',
              marginTop: '8px',
              border: '1px solid #ccc',
            }}
            required
          />
          {isAnswerCorrect === false && (
            <p className="error-text" style={{ color: 'red' }}>
              Incorrect answer. Please try again.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default ReCaptcha;
