import { gql } from '@apollo/client';

const FooterQueryHi = gql
`query MyQuery {
  menu(name: FOOTER, langcode: "hi") {
    name
    items {
      url
      title
    }
  }
}
`;
export default FooterQueryHi;