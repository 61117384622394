import React from 'react';
import BannerImage from './BannerImage';
import AboutImage from './AboutImage';
import SvgShikshaFlower from '../shiksha logo_flower_png.svg';
import LeadershipList from './LeadershipList'; 
import NewsletterList from './NewsletterList';
import EventsList from './EventsList';
import StoriesList from './StoriesList';
import VolunteerForm from '../Form/VolunteerForm'; // Correct import paths
import InternForm from '../Form/InternForm';
import '../../index.css'; // Import the custom CSS file

const NodeContent = ({ node, currentLanguage, leadershipData = [], eventsData = [], storiesData = [], newsletterData = [], baseUrl }) => {
 
  const { title, body, bannerImage, imageAbout, path } = node;
  const isLeadershipPath = path.startsWith('/leadership') || path.startsWith('/hi/leadership');
  const isEventsPath = path.startsWith('/events') || path.startsWith('/hi/events');
  const isStoriesPath = path.startsWith('/stories') || path.startsWith('/hi/stories');
  const isNewsletterPath = path.startsWith('/newsletter-3') || path.startsWith('/hi/newsletter-3');

  return (
    <div className="relative z-0">
      {bannerImage && (
        <BannerImage imageUrl={bannerImage.url} alt={bannerImage.alt} />
      )}
      <div className="mx-auto px-[40px]">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {title}
          <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
            <img src={SvgShikshaFlower} alt="headingflower" className="h-8 sm:h-10 lg:h-12 relative z-10 bg-white p-1 sm:p-2" />
          </div>
        </h2>

        {/* Corrected Conditional Rendering for Forms */}
        {path === '/volunteer' || path === '/hi/volunteer' ? (
          <VolunteerForm node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />
        ) : path === '/intern' || path === '/hi/intern' ? (
          <InternForm node={node} baseUrl={baseUrl} currentLanguage={currentLanguage} />
        ) : null}

        <div className="mt-4 sm:mt-6 lg:mt-8 pb-8">
          {imageAbout && (
            <div className="imggridwrap">
              <AboutImage imageUrl={imageAbout.url} alt={imageAbout.alt} />
            </div>
          )}
          <div className="text-sm sm:text-base lg:text-[15px] text-justify" dangerouslySetInnerHTML={{ __html: body?.value }} />
        </div>
      </div>

      {/* Additional Content Rendering Based on Path */}
      {isLeadershipPath && <LeadershipList leadershipData={leadershipData} currentLanguage={currentLanguage} />}
      {isEventsPath && <EventsList eventsData={eventsData} currentLanguage={currentLanguage} />}
      {isStoriesPath && <StoriesList storiesData={storiesData} currentLanguage={currentLanguage} />}
      {isNewsletterPath && <NewsletterList newsletterData={newsletterData} currentLanguage={currentLanguage} />}
    </div>
  );
};

export default NodeContent;
