import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import EventsQueryEn from '../QueriesEn/EventsQueryEn';
import EventsQueryHi from '../QueriesHi/EventsQueryHi';

const EventsDataFetcher = ({ setEventsData, currentLanguage }) => {
  const EventsQuery = currentLanguage === 'en' ? EventsQueryEn : EventsQueryHi;

  const { data: EventsQueryData, error } = useQuery(EventsQuery, {
    fetchPolicy: 'network-only',
    variables: { language: currentLanguage },
  });

  useEffect(() => {
    if (EventsQueryData) {
      const eventsData = EventsQueryData.nodeEvents.nodes;
      setEventsData(eventsData);
      // console.log('Fetched EvenstQueryData data:', EventsQueryData);
    }
  }, [EventsQueryData, setEventsData]);

  if (error) {
    console.error('Error fetching Events data:', error);
    return <div>Error fetching Events data. Please try again later.</div>;
  }
  return null;
};

export default EventsDataFetcher;
