import React, { useState, useRef, useEffect } from "react";
import BannerImage from "./BannerImage";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";

const VideosContent = ({ node, currentLanguage  }) => {
  const { title, bannerImage, videoSection } = node;

  const [currentPlayingVideo, setCurrentPlayingVideo] = useState(null);
  const videoRefs = useRef([]);

  // Load the YouTube API
  useEffect(() => {
    const loadYouTubeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);
      }
    };
    loadYouTubeAPI();
  }, []);

  // Handle the API ready state
  const onYouTubeIframeAPIReady = () => {
    videoSection.forEach((_, index) => {
      videoRefs.current[index] = new window.YT.Player(`video-${index}`, {
        height: '390',
        width: '640',
        videoId: videoSection[index].videoLinkEmbed.mediaOembedVideo.split("v=")[1],
        events: {
          'onStateChange': (event) => onPlayerStateChange(event, index),
        },
      });
    });
  };

  const onPlayerStateChange = (event, index) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      if (currentPlayingVideo !== null && currentPlayingVideo !== index) {
        videoRefs.current[currentPlayingVideo].pauseVideo();
      }
      setCurrentPlayingVideo(index);
    }
  };

  useEffect(() => {
    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    return () => {
      window.onYouTubeIframeAPIReady = null; // Clean up
    };
  }, [videoSection]);

  return (
    <div className="relative z-0">
      <BannerImage imageUrl={`${bannerImage.url}`} />
      <div className="mx-auto px-[40px]">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {title}
          <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-8 sm:h-10 lg:h-12 relative z-10 bg-white p-1 sm:p-2"
            />
          </div>
        </h1>

        {/* Video Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {videoSection?.map((video, index) => (
            <div
              key={video.id}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              {/* Embedded Video */}
              <div className="video-wrapper">
                <div id={`video-${index}`} className="w-full h-56"></div>
              </div>

              {/* Video Content */}
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2">
                  {video.titleOfVideo}
                </h2>
                <p className="text-gray-600 mb-4">{video.secondDescription}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideosContent;
