import React from 'react';

const statesWithCities = {
    "Andhra Pradesh": [
      "Visakhapatnam", "Vijayawada", "Guntur", "Nellore", "Kurnool", "Rajahmundry", "Tirupati", "Anantapur", "Kakinada", "Eluru"
    ],
    "Arunachal Pradesh": [
      "Itanagar", "Tawang", "Pasighat", "Ziro", "Bomdila", "Aalo", "Naharlagun", "Seppa", "Tezu"
    ],
    "Assam": [
      "Guwahati", "Silchar", "Dibrugarh", "Jorhat", "Nagaon", "Tinsukia", "Tezpur", "Bongaigaon", "Dhubri"
    ],
    "Bihar": [
      "Patna", "Gaya", "Bhagalpur", "Muzaffarpur", "Darbhanga", "Purnia", "Hajipur", "Ara", "Begusarai"
    ],
    "Chhattisgarh": [
      "Raipur", "Bhilai", "Bilaspur", "Korba", "Durg", "Rajnandgaon", "Jagdalpur", "Raigarh", "Ambikapur"
    ],
    "Goa": [
      "Panaji", "Margao", "Vasco da Gama", "Mapusa", "Ponda"
    ],
    "Gujarat": [
      "Ahmedabad", "Surat", "Vadodara", "Rajkot", "Bhavnagar", "Jamnagar", "Junagadh", "Gandhinagar", "Anand"
    ],
    "Haryana": [
      "Faridabad", "Gurugram", "Panipat", "Ambala", "Yamunanagar", "Rohtak", "Hisar", "Karnal", "Panchkula"
    ],
    "Himachal Pradesh": [
      "Shimla", "Manali", "Dharamshala", "Mandi", "Solan", "Kullu", "Hamirpur", "Una", "Bilaspur"
    ],
    "Jharkhand": [
      "Ranchi", "Jamshedpur", "Dhanbad", "Bokaro", "Hazaribagh", "Deoghar", "Giridih", "Ramgarh", "Chaibasa"
    ],
    "Karnataka": [
      "Bengaluru", "Mysuru", "Hubli", "Mangaluru", "Belgaum", "Davanagere", "Bellary", "Shimoga", "Tumkur"
    ],
    "Kerala": [
      "Thiruvananthapuram", "Kochi", "Kozhikode", "Thrissur", "Kollam", "Kannur", "Alappuzha", "Palakkad", "Kottayam"
    ],
    "Madhya Pradesh": [
      "Bhopal", "Indore", "Gwalior", "Jabalpur", "Ujjain", "Sagar", "Rewa", "Ratlam", "Satna"
    ],
    "Maharashtra": [
      "Mumbai", "Pune", "Nagpur", "Thane", "Nashik", "Aurangabad", "Solapur", "Amravati", "Kolhapur"
    ],
    "Manipur": [
      "Imphal", "Bishnupur", "Churachandpur", "Thoubal", "Ukhrul"
    ],
    "Meghalaya": [
      "Shillong", "Tura", "Nongpoh", "Jowai", "Baghmara"
    ],
    "Mizoram": [
      "Aizawl", "Lunglei", "Champhai", "Serchhip", "Kolasib"
    ],
    "Nagaland": [
      "Kohima", "Dimapur", "Mokokchung", "Tuensang", "Wokha"
    ],
    "Odisha": [
      "Bhubaneswar", "Cuttack", "Rourkela", "Sambalpur", "Puri", "Berhampur", "Balasore", "Baripada", "Bhadrak"
    ],
    "Punjab": [
      "Ludhiana", "Amritsar", "Jalandhar", "Patiala", "Bathinda", "Mohali", "Hoshiarpur", "Pathankot", "Moga"
    ],
    "Rajasthan": [
      "Jaipur", "Udaipur", "Jodhpur", "Kota", "Ajmer", "Bikaner", "Bhilwara", "Alwar", "Sikar"
    ],
    "Sikkim": [
      "Gangtok", "Namchi", "Gyalshing", "Mangan", "Rangpo"
    ],
    "Tamil Nadu": [
      "Chennai", "Coimbatore", "Madurai", "Tiruchirappalli", "Salem", "Tirunelveli", "Vellore", "Tiruppur", "Erode"
    ],
    "Telangana": [
      "Hyderabad", "Warangal", "Nizamabad", "Khammam", "Karimnagar", "Ramagundam", "Mahbubnagar", "Adilabad", "Nalgonda"
    ],
    "Tripura": [
      "Agartala", "Udaipur", "Dharmanagar", "Kailasahar", "Khowai"
    ],
    "Uttar Pradesh": [
      "Lucknow", "Kanpur", "Ghaziabad", "Agra", "Varanasi", "Meerut", "Allahabad", "Bareilly", "Aligarh"
    ],
    "Uttarakhand": [
      "Dehradun", "Haridwar", "Roorkee", "Haldwani", "Rudrapur", "Kashipur", "Rishikesh", "Nainital", "Pithoragarh"
    ],
    "West Bengal": [
      "Kolkata", "Howrah", "Durgapur", "Siliguri", "Asansol", "Darjeeling", "Kharagpur", "Haldia", "Jalpaiguri"
    ],
    "Andaman and Nicobar Islands": [
      "Port Blair", "Diglipur", "Car Nicobar", "Havelock Island", "Little Andaman"
    ],
    "Chandigarh": [
      "Chandigarh"
    ],
    "Dadra and Nagar Haveli and Daman and Diu": [
      "Daman", "Diu", "Silvassa", "Amli", "Khanvel"
    ],
    "Lakshadweep": [
      "Kavaratti", "Agatti", "Amini", "Andrott", "Minicoy"
    ],
    "Delhi": [
      "New Delhi", "Delhi", "Dwarka", "Rohini", "Saket"
    ],
    "Puducherry": [
      "Puducherry", "Ozhukarai", "Karaikal", "Yanam", "Mahe"
    ],
    "Ladakh": [
      "Leh", "Kargil"
    ],
    "Jammu and Kashmir": [
      "Srinagar", "Jammu", "Anantnag", "Baramulla", "Udhampur"
    ]
  };
const CitySelect = ({ state, country, value, onChange }) => {
    const stateCities = statesWithCities[state] || [];

 
  
  // Example of accessing a state's cities

  
    return (
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">City</label>
        <select
          name="city"
          value={value}
          onChange={onChange}
          disabled={!state}
          className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
        >
          <option value="">Select a City</option>
          {stateCities.map((city, index) => (
            <option key={index} value={city}>{city}</option>
          ))}
        </select>
      </div>
    );
  };
  
  export default CitySelect;