import { gql } from '@apollo/client';

const VideosQueryHi = gql`
query MyQuery {
  nodeVideos(first: 10, langcode: "hi") {
    nodes {
      id
      path
      title
      __typename
      bannerImage {
        url
        alt
      }
      videoSection {
        ... on ParagraphVideoLink {
          id
          secondDescription
          titleOfVideo
          videoLinkEmbed {
            ... on MediaRemoteVideo {
              id
              name
              path
              mediaOembedVideo
            }
          }
        }
      }
    }
  }
}
`;
export default VideosQueryHi;
