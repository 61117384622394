import React, { useState } from "react";
import Slider from "react-slick";
import BannerImage from "./BannerImage";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";
import "../../index.css";
import { useBaseUrl } from "../../contexts/BaseUrlContext";
import UmangMannualForm from "../Form/UmangMannualForm";
import Lightbox from 'react-image-lightbox';  // Importing the lightbox component
import 'react-image-lightbox/style.css';  // Importing lightbox styles

const ProgramandCampaignContent = ({ node, currentLanguage, umangData }) => {
  const BASE_URL = useBaseUrl();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const slickSettings = { dots: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 5000,
  };

  const { title, body, bannerImage, uploadImage, secondTitle, thirdTitle, thirdSectionDescription, video, path, } = node;
  const images = uploadImage.map((image) => image.mediaImage1.url);

  return (
    <div className="relative z-0">
      {bannerImage && (
        <BannerImage imageUrl={bannerImage.url} alt={bannerImage.alt} />
      )}
      <div className="mx-auto px-[40px]">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {title}
          <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-8 sm:h-10 lg:h-12 relative z-10 bg-white p-1 sm:p-2"
            />
          </div>
        </h1>
        <div className="lg:inline-block sm:block xs:block text-[15px] text-justify">
          {uploadImage && uploadImage.length > 0 && (
            <div className="float-none sm:float-right w-full sm:w-[380px] sm:ml-[50px] mb-[30px]">
              <Slider {...slickSettings}>
                {uploadImage.map((image, index) => (
                  <div key={index} className="item">
                    <div
                      className="relative cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        openLightbox(index);
                      }}

                    >
                      <img src={image.mediaImage1.url} alt={image.mediaImage1.alt} className="rounded-xl" />
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 bg-black bg-opacity-50 rounded-xl">
                        <svg
                          className="w-10 h-10"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        >
                          <path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5zm-4.5 8h4v-4h1v4h4v1h-4v4h-1v-4h-4v-1z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}
          <div dangerouslySetInnerHTML={{ __html: body.value }} />
        </div>
      </div>

      <section>
        <div>
          {path === "/campaigns/shiksha-umang" ||
          path === "/hi/campaigns/shiksha-umang" ? (
            <UmangMannualForm
              umangData={umangData}
              currentLanguage={currentLanguage}
            />
          ) : null}
        </div>
      </section>

      <section
        style={{
          backgroundColor: "#eceff1",
          backgroundImage: `url("${BASE_URL}/images/flowerinbg.png")`,
          backgroundPosition: "-120px 50%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="mx-auto px-[40px] my-4">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
            {secondTitle}
            <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
              <img
                src={SvgShikshaFlower}
                alt="headingflower"
                className="h-8 sm:h-10 lg:h-12 relative z-10 p-1 sm:p-2"
              />
            </div>
          </h2>
          <div className="mt-[30px] responsive-video mx-auto">
            <img src={require("../laptop.png")} className="w-full" />
            {video && (
              <video controls>
                <source src={video.mediaVideoFile.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      </section>

      {path !== "/campaigns/shiksha-umang" ||
      path !== "/hi/campaigns/shiksha-umang" ? (
        <section>
          <div className="mx-auto px-[40px]">
            <h3 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
              {thirdTitle}
              <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
                <img
                  src={SvgShikshaFlower}
                  alt="headingflower"
                  className="h-8 sm:h-10 lg:h-12 relative z-10 p-1 sm:p-2"
                />
              </div>
            </h3>
            <p className="text-justify mb-[20px]">{thirdSectionDescription}</p>
          </div>
        </section>
      ) : null}
     {/* Lightbox component */}
     {lightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          imageTitle={`Image ${photoIndex + 1} of ${images.length}`}
        />
      )}

    </div>
  );
};

export default ProgramandCampaignContent;
