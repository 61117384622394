import React, { useState, useEffect } from "react";
import yaml from "js-yaml";
import CountrySelect from "./CountrySelect";
import StateSelect from "./StateSelect";
import CitySelect from "./CitySelect";
import { useNavigate } from "react-router-dom";

const InternForm = ({ node, currentLanguage }) => {
  const [webformData] = useState({
    title: "Join as a Intern",
    description: "",
    elements: `
      personal_information:
        '#type': fieldset
        '#title': 'Personal Information'
        fullname:
          '#type': textfield
          '#title': FullName
          '#placeholder': 'Enter Your Name'
          '#required': true
          '#required_error': 'Enter your name field is required'
        dob:
          '#type': date
          '#title': 'Date of Birth'
          '#placeholder': 'Enter your DOB'
          '#required': true
          '#required_error': 'This field is required'
          '#format': d/m/y
          '#date_date_max': today
        gender:
          '#type': select
          '#title': Gender
          '#options':
            Male: Male
            Female: Female
            Other: Other
          '#required': true
          '#required_error': 'This field is required'
        nationality:
          '#type': textfield
          '#title': Nationality
          '#placeholder': 'Enter your Nationality'
          '#required': true
          '#required_error': 'This field is required'
      contact:
        '#type': fieldset
        '#title': 'Contact Information'
        address:
          '#type': textarea
          '#title': Address
          '#placeholder': 'Enter your Address'
          '#required': true
          '#required_error': 'This field is required'
        country:
          '#type': select
          '#title': Country
          '#options': country_names
          '#required': true
          '#required_error': 'This field is required'
        state:
          '#type': select
          '#title': State
          '#options': state_names
          '#required': true
          '#required_error': 'This field is required'
        city:
          '#type': select
          '#title': City
          '#options': province_names
          '#required': true
          '#required_error': 'This field is required'
        zipcode:
          '#type': textfield
          '#title': 'Zip Code '
          '#placeholder': 'Enter your zip code'
          '#required': true
          '#required_error': 'This field is required'
        mobile:
          '#type': tel
          '#title': Mobile
          '#placeholder': 'Enter your mobile number'
          '#required': true
          '#required_error': 'This field is required'
        mail:
          '#type': email
          '#title': Email
          '#placeholder': 'Enter your Email'
          '#required': true
          '#required_error': 'This field is required'
      education:
        '#type': fieldset
        '#title': Education
        degree:
          '#type': textfield
          '#title': Degree
          '#placeholder': 'Enter Your Degree'
          '#required': true
          '#required_error': 'This field is required'
        university:
          '#type': textfield
          '#title': University
          '#placeholder': 'Enter Your University'
          '#required': true
      workexperience:
        '#type': fieldset
        '#title': 'Work Experience (If Applicable)'
        numberofyears:
          '#type': number
          '#title': 'Number of years'
          '#placeholder': 'Enter Your number of years'
          '#required': true
          '#required_error': 'This field is required'
        industry:
          '#type': textfield
          '#title': Industry
          '#placeholder': 'Enter Your industry'
          '#required': true
          '#required_error': 'This field is required'
        profession:
          '#type': textfield
          '#title': Profession
          '#placeholder': 'Enter Your Profession'
          '#required': true
        companyname:
          '#type': textfield
          '#title': '  Company Name'
          '#placeholder': 'Enter Your Company Name'
          '#required': true
          '#required_error': 'This field is required'
      language:
        '#type': fieldset
        '#title': 'Language Proficiency'
        '#placeholder': 'Enter Your Language'
        languagesknown:
          '#type': textfield
          '#title': 'Languages Known'
          '#required': true
          '#required_error': 'This field is required'
      areasofinterest:
        '#type': fieldset
        '#title': 'Areas of Interest for Volunteering'
        '#placeholder': 'Enter Your Volunteer'
        program:
          '#type': select
          '#title': Program
          '#options':
            'Shiksha Plus': 'Shiksha Plus'
            Elementary: Elementry
          '#required': true
          '#required_error': 'This field is required'
        location:
          '#type': select
          '#title': Location
          '#options':
            Delhi: Delhi
            Noida: Noida
            Dadri: Dadri
          '#required': true
        durationofinvolvement:
          '#type': textfield
          '#title': 'Duration of Involvement'
          '#placeholder': 'Enter Your Duration of Involvement'
          '#required': true
          '#required_error': 'This field is required'
        whydoyouwanttojointhisprogram:
          '#type': textarea
          '#title': 'Why do you want to join this program?'
          '#required': true
          '#required_error': 'This field is required'
      actions:
        '#type': webform_actions
        '#title': 'Submit button(s)'
        '#submit__label': 'Submit'
    `,
  });

  const { title, body, bannerImage, imageAbout, path } = node;
  const [formValues, setFormValues] = useState({});
  const [error, setError] = useState(null);
  const [csrfToken, setCsrfToken] = useState("");
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(
          "https://shikshacms.shikshainitiative.org/session/token?_format=json"
        );
        if (!response.ok) throw new Error("Failed to fetch CSRF token");
        const data = await response.json();
        setCsrfToken(data);
      } catch (err) {
        console.error("CSRF Token Error:", err);
      }
    };

    fetchCsrfToken();
  }, []);


  const getMaxDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18); // Set date to 18 years ago
    return today.toISOString().split("T")[0]; // Format as yyyy-mm-dd
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const requestData = {
        webform_id: "join_as_a_intern",
        ...formValues,
      };

      console.log("Request Data:", requestData);

      // Make sure to await inside the try block and use only one response variable
      const response = await fetch(
        "https://shikshacms.shikshainitiative.org/webform_rest/submit?_format=json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Basic c2hpc3NoYV9hZG1pbjpTaGlrc2hhaGNsQDIwMjQ=",
            "X-CSRF-Token": csrfToken,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text(); // Read the error as text
        console.error("Error Response:", errorText);
        throw new Error(
          `HTTP error! Status: ${response.status}, Response: ${errorText}`
        );
      }

      const data = await response.json();
      console.log("Submission Response:", data);

      // Show success message and reset form
      setShowSuccessMessage(true);
      setFormValues({});

      // Redirect to /volunteer page after a short delay
      setTimeout(() => {
        setShowSuccessMessage(false); // Hide the message before redirect
        navigate("/intern");
      }, 3000);
    } catch (err) {
      console.error("Submission Error:", err);
      setError(err);
    }
  };

  const renderField = (key, config) => {
    // Check if the config is an object before trying to render its fields
    if (typeof config !== "object" || config === null) {
      console.error(`Invalid config for key: ${key}`, config);
      return null;
    }

    switch (config["#type"]) {
      case "fieldset":
        return (
          <fieldset key={key} className="mb-6 w-full">
            <legend className="block text-gray-700 text-xl font-bold mb-4">
              {config["#title"]}
            </legend>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {Object.entries(config).map(([innerKey, innerConfig]) =>
                renderField(innerKey, innerConfig)
              )}
            </div>
          </fieldset>
        );

      // Handle other field types here...
      case "textfield":
        return (
          <div key={key} className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {config["#title"]}
            </label>
            <input
              type="text"
              name={key}
              required={config["#required"]}
              placeholder={config["#placeholder"]}
              onChange={handleInputChange}
              value={formValues[key] || ""}
              className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
            />
          </div>
        );
      case "tel":
        return (
          <div key={key} className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {config["#title"]}
            </label>
            <input
              type="tel"
              name={key}
              required={config["#required"]}
              placeholder={config["#placeholder"]}
              onChange={handleInputChange}
              value={formValues[key] || ""}
              className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
            />
          </div>
        );
      case "email":
        return (
          <div key={key} className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {config["#title"]}
            </label>
            <input
              type="email"
              name={key}
              required={config["#required"]}
              placeholder={config["#placeholder"]}
              onChange={handleInputChange}
              value={formValues[key] || ""}
              className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
            />
          </div>
        );
      case "number":
        return (
          <div key={key} className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {config["#title"]}
            </label>
            <input
              type="number"
              name={key}
              required={config["#required"]}
              placeholder={config["#placeholder"]}
              onChange={handleInputChange}
              value={formValues[key] || ""}
              className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
            />
          </div>
        );
      case "select":
        if (key === "country") {
          return (
            <CountrySelect
              key={key}
              name={key}
              required={config["#required"]}
              value={formValues[key] || ""}
              onChange={handleInputChange}
            />
          );
        } else if (key === "state") {
          return (
            <StateSelect
              key={key}
              country={formValues["country"]} // Pass the selected country to StateSelect
              value={formValues[key] || ""}
              onChange={handleInputChange}
            />
          );
        } else if (key === "city") {
          return (
            <CitySelect
              country={formValues["country"]}
              state={formValues["state"]}
              value={formValues[key] || ""}
              onChange={handleInputChange}
            />
          );
        }
        if (key === "gender") {
          return (
            <div className="mb-4" key={key}>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Gender
              </label>
              <select
                name="gender"
                value={formValues[key] || ""}
                onChange={handleInputChange}
                required={config["#required"]}
                className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          );
        }
        if (key === "location") {
          return (
            <div className="mb-4" key={key}>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Location
              </label>
              <select
                name={key}
                value={formValues[key] || ""}
                onChange={handleInputChange}
                required={config["#required"]}
                className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
              >
                <option value="">Select Location</option>
                <option value="Delhi">Delhi</option>
                <option value="Noida">Noida</option>
                <option value="Dadri">Dadri</option>
              </select>
            </div>
          );
        }
        if (key === "program") {
          return (
            <div className="mb-4" key={key}>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Program
              </label>
              <select
                name={key}
                value={formValues[key] || ""}
                onChange={handleInputChange}
                required={config["#required"]}
                className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
              >
                <option value="">Select Program</option>
                <option value="Shiksha Plus">Shiksha Plus</option>
                <option value="Elementary">Elementary</option>
              </select>
            </div>
          );
        }
        return (
          <div key={key} className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {config["#title"]}
            </label>
            <select
              name={key}
              onChange={handleInputChange}
              value={formValues[key] || ""}
              placeholder={config["#placeholder"]}
              className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
            >
              {Object.entries(config["#options"]).map(
                ([optionKey, optionValue]) => (
                  <option key={optionKey} value={optionKey}>
                    {optionValue}
                  </option>
                )
              )}
            </select>
          </div>
        );
      case "textarea":
        return (
          <div key={key} className="col-span-full">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {config["#title"]}
            </label>
            <textarea
              name={key}
              required={config["#required"]}
              placeholder={config["#placeholder"]}
              onChange={handleInputChange}
              value={formValues[key] || ""}
              className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
            />
          </div>
        );
      case "date":
        return (
          <div key={key} className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {config["#title"]}
            </label>
            <input
              type="date"
              name={key}
              required={config["#required"]}
              placeholder={config["#placeholder"]}
              max={getMaxDate()} // Set max date to today - 18 years
              onChange={handleInputChange}
              value={
                formValues[key]
                  ? formValues[key].split("/").reverse().join("-")
                  : ""
              }
              className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="max-w-4xl mx-auto p-6 bg-white rounded shadow-md"
      >
        <p className="text-center mb-4">{webformData.description}</p>

        {Object.entries(yaml.load(webformData.elements)).map(([key, config]) =>
          renderField(key, config)
        )}

        <div className="text-center mt-6">
          <button
            type="submit"
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
        {error && (
          <p className="text-red-500 text-center mt-4">
            Error: {error.message}
          </p>
        )}
      </form>
      {/* Success Message Popup */}
      {showSuccessMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md text-center">
            <h3 className="text-lg font-bold mb-4">Submission Successful!</h3>
            <p>Your record has been submitted. We will get back to you soon.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default InternForm;
