// BannerImage.js
import React from "react";
import Breadcrumb from "./Breadcrumb"; // Import the Breadcrumb component

const BannerImage = ({ imageUrl }) => {
  // Set a minimum height for the banner area if no image URL is provided
  const bannerHeight = imageUrl ? "h-auto" : "h-[100px]"; // Adjust height as needed

  return (
    <div className={`relative mb-4 pt-[72px] ${bannerHeight}`}>
      {imageUrl && (
        <img
          src={imageUrl}
          alt="Banner"
          className="w-full object-cover lg:h-[430.16px] sm:h-[300.375px] xs:h-[167.198px] xxs:h-[167.198px]"
        />
      )}
      <div className="absolute bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.3)] p-2 sm:p-4">
        <Breadcrumb /> {/* Render the Breadcrumb component */}
      </div>
    </div>
  );
};

export default BannerImage;
