// Modal.js
import React from 'react';

const Modal = ({ isOpen, closeModal, children }) => {
  if (!isOpen) return null; // Do not render if the modal is not open

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg relative">
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          &#10005; {/* Close button symbol (X) */}
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
