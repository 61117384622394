import { gql } from '@apollo/client';

const ReachandImpactQueryEn = gql
`query MyQuery {
  nodeReachAndImpacts(first: 10, langcode: "en") {
    nodes {
      title
      reachAndImpact {
        ... on ParagraphReachAndImpact {
          id
          titleOfReachAndImpact
          numberOfReachAndImpact
        }
      }
      bannerImage {
        url
        alt
      }
      body {
        value
      }
      id
      path
    }
  }
}
`;
export default ReachandImpactQueryEn;