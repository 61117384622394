import React, { useEffect }  from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';


const NewsletterDetail = ({ newsletterData, currentLanguage }) => {
    const { newsletterId } = useParams();
    const newsletter = newsletterData.find((e) => e.path.split("/").pop() === newsletterId);

    const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [location]); 
  
    if (!newsletter) {
        return <div className="text-center text-gray-600">Newsletter not found</div>;
    }

    return (
        <section className="relative z-0 pb-10 pt-20">
            <div className="container mx-auto px-5">
                {/* Back to list link */}
                <Link 
                    to={`/${currentLanguage === 'hi' ? 'hi/' : ''}newsletter-3`} 
                    className="text-blue-600 hover:underline"
                >
                    &larr; Back to Newsletter List
                </Link>
                <Helmet>
        <title>{newsletter.title}</title> {/* Set dynamic title here */}
      </Helmet>
                {/* Title */}
                <h1 className="text-4xl font-bold text-gray-800 mb-5">{newsletter.title}</h1>
                
                {/* Banner Image */}
                {newsletter.bannerImage && (
                    <div className="my-5">
                        <img 
                            src={newsletter.bannerImage.url} 
                            alt={newsletter.bannerImage.alt || "Banner"} 
                            className="w-full h-[25rem] object-cover rounded-lg shadow-lg"
                        />
                    </div>
                )}

                {/* Newsletter Description */}
                <div className="text-gray-700 leading-relaxed">
                    <div 
                        dangerouslySetInnerHTML={{ __html: newsletter?.newsletterDescription?.value }} 
                    />
                </div>

                {/* Newsletter PDF Link */}
                {newsletter.oldNewsletterPdf && (
                    <div className="mt-10">
                        <a 
                            href={newsletter.oldNewsletterPdf.mediaFile.url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline"
                        >
                            Download PDF
                        </a>
                    </div>
                )}
            </div>
        </section>
    );
};

export default NewsletterDetail;
