import { gql } from '@apollo/client';

const HomePageQueryHi = gql`
query MyQuery {
  nodeHomePages(first: 10, langcode: "hi") {
    nodes {
      title
      __typename
      impactTitle
      impactSectionDescription
      body {
        value
      }
      bannerVideo {
        ... on MediaVideo {
          id
          name
          mediaVideoFile {
            url
            name
          }
        }
      }
      title
      path
      impactSectionImage {
        ... on MediaImage {
          id
          name
          mediaImage {
            url
          }
        }
      }
      impactSection {
        ... on ParagraphReachAndImpact {
          id
          numberOfReachAndImpact
          titleOfReachAndImpact
        }
      }
      testimonials {
        ... on ParagraphTestimonial {
          id
          nameOfTestimonials
          postsNameAndPlace
          testimonialsDescription
        }
      }
    }
  }
}
`;
export default HomePageQueryHi;