import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import StoriesQueryEn from '../QueriesEn/StoriesQueryEn';
import StoriesQueryHi from '../QueriesHi/StoriesQueryHi';

const StoriesDataFetcher = ({ setStoriesData, currentLanguage }) => {
  const StoriesQuery = currentLanguage === 'en' ? StoriesQueryEn : StoriesQueryHi;

  const { data: StoriesQueryData, error } = useQuery(StoriesQuery, {
    fetchPolicy: 'network-only',
    variables: { language: currentLanguage },
  });

  useEffect(() => {
    if (StoriesQueryData) {
      const storiesData = StoriesQueryData.nodeStoriesOfChangeAndTestimonia.nodes;
      setStoriesData(storiesData);
      // console.log('Fetched StoriesQueryData data:', StoriesQueryData);
    }
  }, [StoriesQueryData, setStoriesData]);

  if (error) {
    console.error('Error fetching Stories data:', error);
    return <div>Error fetching Stories data. Please try again later.</div>;
  }
  return null;
};

export default StoriesDataFetcher;
