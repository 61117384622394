import { gql } from '@apollo/client';

const MainNavigationMenu = gql`
query MyQuery {
  menu(name: MAIN, langcode: "en") {
    id
    items {
      title
      url
      children {
        title
        url
      }
    }
  }
}
`;
 export default MainNavigationMenu;