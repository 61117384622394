import { gql } from '@apollo/client';

const FormQueryHi = gql`
query MyQuery {
  nodeWebforms(first: 10) {
    nodes {
      id
      title
      path
      __typename
    }
  }
}`;
export default FormQueryHi;