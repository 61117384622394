import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import ProgramandCampaignQueryEn from '../QueriesEn/ProgramandCampaignQueryEn';
import ProgramandCampaignQueryHi from '../QueriesHi/ProgramandCampaignQueryHi';

const ProgramandCampaignDataFetcher = ({ setProgramAndCampaignData, currentLanguage }) => {
  const programAndCampaignQuery = currentLanguage === 'en' ? ProgramandCampaignQueryEn : ProgramandCampaignQueryHi;

  const { loading, error, data } = useQuery(programAndCampaignQuery, {
    fetchPolicy: 'network-only',
    variables: { language: currentLanguage },
  });

  useEffect(() => {
    if (error) {
      console.error('Error fetching Program and Campaign data:', error);
    }
    if (data) {
      const nodes = data.nodeProgramAndCampaigns.nodes;
      setProgramAndCampaignData(nodes);
      // console.log('Fetched Program and Campaign data:', nodes);
    }
  }, [data, error, setProgramAndCampaignData]);

  if (loading) return <p>Loading Program and Campaign data...</p>;
  return null;
};

export default ProgramandCampaignDataFetcher;
