import React, { useState } from "react";
import Slider from "react-slick";
import "../../index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";

const Testimonials = ({ testimonials = [] }) => {
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleReadMore = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
  };

  // Slider settings for showing three cards at a time
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="mx-auto px-[40px]">

    <div className="w-full mt-10 px-4 mb-12">
      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
        Testimonials
        <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
          <img
            src={SvgShikshaFlower}
            alt="headingflower"
            className="h-8 sm:h-10 lg:h-12 p-1 sm:p-2"
          />
        </div>
      </h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => {
          const isExpanded = expandedCard === index;
          const description = testimonial.testimonialsDescription || "";
          const displayDescription = isExpanded
            ? description
            : description.slice(0, 170);

          return (
            <div key={index} className="p-4">
              <div className="border rounded-lg shadow-md p-6 h-[250px] overflow-hidden flex flex-col justify-between bg-white hover:bg-yellow-300">
                {/* Card Content */}
                <div className="flex flex-col items-center text-center h-full">
                  <div
                    className="overflow-y-auto"
                    style={{ maxHeight: "150px" }}
                  >
                    <p className="text-gray-700 italic text-xl">
                      <strong className="font-extrabold text-xl">"</strong>{displayDescription}<strong className="font-extrabold">"</strong>
                    </p>
                    {description.length > 170 && (
                      <span
                        onClick={() => toggleReadMore(index)}
                        className="text-navy-blue cursor-pointer ml-1"
                      >
                        {isExpanded ? "Show Less" : "Read More"}
                      </span>
                    )}
                  </div>
                  {/* Name and Post Info */}
                  <div className="mt-4">
                    <h3 className="text-xl font-semibold">
                      {testimonial.nameOfTestimonials}
                    </h3>
                    <p className="text-gray-500">
                      {testimonial.postsNameAndPlace}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
    </div>
  );
};

export default Testimonials;
