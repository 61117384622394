import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import UmangQueryEn from '../QueriesEn/UmangQueryEn';

const UmangDataFetcher = ({ setUmangData }) => {
    const umangContentQuery = UmangQueryEn
  
    const { loading, error, data } = useQuery(umangContentQuery, {
      fetchPolicy: 'network-only',
    });
  
    useEffect(() => {
      if (error) {
        console.error('Error fetching page items:', error);
      }
      if (data) {
        const nodes = data.nodeResumeUploads.nodes;
        setUmangData(nodes);
        // console.log('Fetched umangnodes data:', nodes);
      }
    }, [data, error, setUmangData]);
  
    if (loading) return <p>Loading...</p>;
    return null;
  };
  
  export default UmangDataFetcher;
  