import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import NewsletterQueryEn from '../QueriesEn/NewsletterQueryEn';
import NewsletterQueryHi from '../QueriesHi/NewsletterQueryHi';

const NewsletterDataFetcher = ({ setNewsletterData, currentLanguage }) => {
  const newsletterQuery = currentLanguage === 'en' ? NewsletterQueryEn : NewsletterQueryHi;

  const { data: newsletterQueryData, error } = useQuery(newsletterQuery, {
    fetchPolicy: 'network-only',
    variables: { language: currentLanguage },
  });

  useEffect(() => {
    if (newsletterQueryData) {
      const newsletterData = newsletterQueryData.nodeSimplenewsIssues.nodes;
      setNewsletterData(newsletterData);
      // console.log('Fetched newsletter data:', newsletterData);
    }
  }, [newsletterQueryData, setNewsletterData]);



  if (error) {
    console.error('Error fetching newsletter data:', error);
    return <div>Error fetching newsletter data. Please try again later.</div>;
  }

  return null;
};

export default NewsletterDataFetcher;