import React from "react";
import BannerImage from "./BannerImage";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";

const AnnualReportContent = ({ node, currentLanguage  }) => {
  const { title, bannerImage, annualReportSection } = node;

  return (
    <div className="relative z-0">
      <BannerImage imageUrl={`${bannerImage.url}`} />
      <div className="mx-auto px-[40px]">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {title}
          <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
            <img
              src={SvgShikshaFlower}
              alt="headingflower"
              className="h-8 sm:h-10 lg:h-12 relative z-10 bg-white p-1 sm:p-2"
            />
          </div>
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {annualReportSection.map((section) => (
            <div key={section.id} className="mb-6 max-w-xs  max-h-[500px] px-2">
              {/* PDF Cover Image */}
              <a
                href={section.annualReportFile?.mediaDocument?.url}
                download={section.annualReportFile?.mediaDocument?.name}
                target="_blank" // This opens the link in a new tab
                rel="noopener noreferrer" // Security measure
              >
                <img
                  src={section.pdfCoverImage?.mediaImage3?.url}
                  alt={section.pdfCoverImage?.mediaImage3?.alt}
                  className="w-full object-contain rounded-md cursor-pointer"
                />
                <h3 className="text-lg text-navy-blue text-center font-medium mt-4">
                  {section.titleOfPdf}
                </h3>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnnualReportContent;
