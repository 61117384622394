import { gql } from '@apollo/client';

const NewsletterQueryHi = gql`
query MyQuery {
  nodeSimplenewsIssues(first: 10, langcode: "hi") {
    nodes {
      title
      __typename
       typeOfNewsletter {
        ... on TermNewsletterTypeOld {
          id
          name
        }
      }
      shortDescription
      oldNewsletterPdf {
        ... on MediaOldNewsletterPdf {
          id
          name
          mediaFile {
            url
            name
          }
        }
      }
      newsletterCoverImage {
        ... on MediaNewsletterCoverImage {
          id
          name
          mediaImage6 {
            url
            alt
          }
        }
      }
      bannerImage {
        title
        url
        alt
      }
      newsletterDescription {
        value
      }
      path
    }
  }
}


`;
export default NewsletterQueryHi;
