import { gql } from '@apollo/client';

const NodeContentQueryHi = gql`
query MyQuery {
  nodePages(first: 10, langcode: "hi") {
    nodes {
      __typename
      bannerImage {
        url
        alt
      }
      body {
        value
      }
      imageAbout {
        url
      }
      path
      title
    }
  }
}
`;
// console.log(NodeContentQueryHi);
export default NodeContentQueryHi;