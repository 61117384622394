import React, { useEffect, useRef, useState } from "react";
import BannerImage from "./BannerImage";
import SvgShikshaFlower from "../shiksha logo_flower_png.svg";
import '../../index.css'; // Import the custom CSS file

const ReachandImpactContent = ({ node, isMobileMenuOpen }) => {
  // Ref to track if the animation has been run
  const animationDone = useRef(false);

  const { title, bannerImage, reachAndImpact } = node;

  // Animation for counting numbers
  const CountAnimation = ({ value }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      if (animationDone.current) {
        setCount(value); // If animation is already done, set the count to final value
        return;
      }

      const step = Math.ceil(value / 100); // Calculate the step size
      const intervalDuration = 2000 / (value / step); // Adjust the interval duration dynamically

      const intervalId = setInterval(() => {
        setCount((prevCount) => {
          const nextCount = prevCount + step;
          if (nextCount >= value) {
            clearInterval(intervalId);
            animationDone.current = true; // Mark animation as done
            return value;
          }
          return nextCount;
        });
      }, intervalDuration);

      // Cleanup interval on unmount
      return () => clearInterval(intervalId);
    }, [value]);

    return <>{count}</>;
  };

  return (
    <div className="relative z-0">
      {bannerImage && (
        <BannerImage imageUrl={bannerImage.url} alt={bannerImage.alt} />
      )}
      <div className="mx-auto px-[40px]">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-poppins font-bold uppercase relative text-navy-blue text-center section-heading">
          {title}
          <div className="relative flex items-center justify-center mb-4 sm:mb-6 lg:mb-8 line-with-image">
            <img src={SvgShikshaFlower} alt="headingflower" className="h-8 sm:h-10 lg:h-12 relative z-10 bg-white p-1 sm:p-2" />
          </div>
        </h2>
        <section>
          <div className="flex flex-wrap -mx-4">
            {reachAndImpact.length > 0 ? (
              reachAndImpact.map((paragraph) => (
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mb-5 px-4" key={paragraph.id}>
                  <div className="h-full py-10 px-2.5 w-full shadow-lg text-center font-medium text-base text-custom-color">
                    <div className="mb-6">
                      <div className="text-[30px] text-navy-blue pb-4">
                        <CountAnimation value={parseInt(paragraph.numberOfReachAndImpact)} />
                        <sup>+</sup>
                      </div>
                      <p>{paragraph.titleOfReachAndImpact}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No details available.</p>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default ReachandImpactContent;
