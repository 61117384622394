import { gql } from '@apollo/client';

const FooterQueryEn = gql
`query MyQuery {
  menu(name: FOOTER, langcode: "en") {
    name
    items {
      url
      title
    }
  }
}
`;
export default FooterQueryEn;