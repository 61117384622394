import React from "react";
import { Link } from "react-router-dom";

const extractSummary = (htmlContent, maxLength = 170) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlContent;
  const textContent = tempElement.textContent || tempElement.innerText || "";

  if (textContent.length > maxLength) {
    return textContent.substring(0, maxLength) + "...";
  }

  return textContent;
};

const EventsList = ({ eventsData, currentLanguage }) => {


  // Debugging log to verify currentLanguage prop
  // console.log("Current Language:", currentLanguage);

  return (
    <section>
      <div className="bg-gray-300">
        <div className="mx-auto px-[40px]">
          <div className="flex flex-wrap pt-5">
            {eventsData.map((events) => {
              const summary = extractSummary(events.body.value, 170);
              return (
                <div key={events.id} className="md:w-1/3 mb-5 px-3">
                  <div className="right-border-around relative w-full pt-15 pr-15 pb-0 pl-0">
                    <img
                      src={events.imageAbout.url}
                      alt={events.imageAbout.alt}
                      className="relative w-full z-10"
                    />
                  </div>
                  <div className="bg-[#ffffff] h-auto sm:h-auto lg:h-auto flex flex-col justify-between p-4">
                    <div>
                      <h2 className="text-[18px] text-[#263238] text-justify font-bold p-[20px_10px_10px] mt-[-20px] lg:line-clamp-1 sm:line-clamp-none lg:h-12">
                        {events.title}
                      </h2>
                      <p className="text-justify text-sm pb-2 px-2 overflow-hidden h-16 line-clamp-2">{summary}</p>
                    </div>
                    <div className="text-center">
                      <Link
                        to={`/${currentLanguage === 'en' ? '' : 'hi/'}events/${events.path.split("/").pop()}`}
                      >
                        <button
                          type="button"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-4"
                        >
                          {currentLanguage === 'en' ? 'Read More' : 'अधिक पढ़ें'}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventsList;
